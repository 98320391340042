import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import BTextInputValidation from '@/components/ui/inputs/b-text-input-validation/bTextInputValidation.vue';
import BSelectInputValidation from '@/components/ui/inputs/b-select-input-validation/bSelectInputValidation.vue';
import BMarkdownInput from '@/components/ui/inputs/b-markdown-input/bMarkdownInput.vue';
import {
  OfferChangeEventOfferStatusRequest,
  OfferChangeSpotOfferStatusRequest,
  OfferCreateEventRequest,
  OfferCreateSpotRequest, OfferEventUsersRequest, OfferSpotUsersRequest, OfferUpdateEventRequest,
  OfferUpdateLocaleRequest, OfferUpdateSpotRequest
} from '@/types/request/offer-request.types';
import { SpotOfferType } from '@/types/api/offer/spot-offer.type';
import { EventOfferType } from '@/types/api/offer/event-offer.type';
import VisibleIcon from '@/components/ui/visible-icon/visibleIcon.vue';
import { LanguageType } from '@/types/api/info/language.type';
import DatepickerSingle from '@/components/ui/inputs/datepicker-single/datepickerSingle.vue';
import { ValidationObserver } from 'vee-validate';
import LocaleUpdater from '@/components/ui/locale-updater/localeUpdater.vue';
import { LocaleUpdateEvent } from '@/components/ui/locale-updater/localeUpdater';
import { SpotOfferLocaleType } from '@/types/api/offer/spot-offer-locale.type';
import { EventOfferLocaleType } from '@/types/api/offer/event-offer-locale.type';
import { IOfferCard } from '@/store/modules/offer/actions';
import Layout from '@/components/layouts/main.vue';
import PageTitle from '@/components/ui/page-title/pageTitle.vue';
import { AgGridVue } from 'ag-grid-vue';
import { AgGridHelper } from '@/helpers/ag-grid/ag-grid.helper';
import { ICallbackAdditionalParams, ICallbackGeneralParams } from '@/helpers/ag-grid/ag-grid-datasource';
import { ColDef, GridOptions, ICellRendererParams, ValueGetterParams } from 'ag-grid-community';
import { DATE_FORMAT, getDateFromISO } from '@/helpers/date.helper';
import FormBlock from '@/components/ui/form-block/form-block.vue';
import { SpotOfferUserType } from '@/types/api/offer/spot-offer-user.type';
import { EventOfferUserType } from '@/types/api/offer/event-offer-user.type';
import { getLanguageByCode, getUserName } from '@/helpers/app.helper';

const TYPE_SPOT = 'spot';
const TYPE_EVENT = 'event';
const title = 'offer.updateTitle';

@Component({
  page: {
    title
  },
  name: 'OfferCard',
  components: {
    Layout,
    PageTitle,
    BTextInputValidation,
    BSelectInputValidation,
    BMarkdownInput,
    VisibleIcon,
    DatepickerSingle,
    ValidationObserver,
    LocaleUpdater,
    AgGridVue,
    FormBlock,
  },
})
export default class OfferCard extends Vue {
  title = title;
  tryingToUpdate = false;
  language = '';
  name = '';
  description = '';
  details = '';
  limit = '';
  startAt = '';
  endAt = '';
  expiresAt = '';
  locales: SpotOfferLocaleType[] | EventOfferLocaleType[] | any = [];
  type = TYPE_SPOT;
  entityId;
  entity: SpotOfferType | EventOfferType | null = null;
  agGrid: AgGridHelper | null = null;

  @Action('getOfferSpot', { namespace: 'offer' })
  getOfferSpot!: (id: number) => Promise<SpotOfferType>;

  @Action('getOfferEvent', { namespace: 'offer' })
  getOfferEvent!: (id: number) => Promise<EventOfferType>;

  @Action('createOfferSpot', { namespace: 'offer' })
  createOfferSpot!: (params: OfferCreateSpotRequest) => Promise<SpotOfferType>;

  @Action('updateOfferSpot', { namespace: 'offer' })
  updateOfferSpot!: (data: IOfferCard<OfferUpdateSpotRequest>) => Promise<SpotOfferType>;

  @Action('createOfferEvent', { namespace: 'offer' })
  createOfferEvent!: (params: OfferCreateEventRequest) => Promise<EventOfferType>;

  @Action('updateOfferEvent', { namespace: 'offer' })
  updateOfferEvent!: (data: IOfferCard<OfferUpdateEventRequest>) => Promise<EventOfferType>;

  @Action('createOfferSpotLocale', { namespace: 'offer' })
  createOfferSpotLocale!: (data: IOfferCard<OfferUpdateLocaleRequest>) => Promise<SpotOfferLocaleType>;

  @Action('updateOfferSpotLocale', { namespace: 'offer' })
  updateOfferSpotLocale!: (data: IOfferCard<OfferUpdateLocaleRequest>) => Promise<SpotOfferLocaleType>;

  @Action('createOfferEventLocale', { namespace: 'offer' })
  createOfferEventLocale!: (data: IOfferCard<OfferUpdateLocaleRequest>) => Promise<EventOfferLocaleType>;

  @Action('updateOfferEventLocale', { namespace: 'offer' })
  updateOfferEventLocale!: (data: IOfferCard<OfferUpdateLocaleRequest>) => Promise<EventOfferLocaleType>;

  @Action('getOfferSpotUsers', { namespace: 'offer' })
  getOfferSpotUsers!: (data: IOfferCard<OfferSpotUsersRequest>) => Promise<SpotOfferUserType>;

  @Action('getOfferEventUsers', { namespace: 'offer' })
  getOfferEventUsers!: (data: IOfferCard<OfferEventUsersRequest>) => Promise<EventOfferUserType>;

  @Action('deleteOfferSpotUser', { namespace: 'offer' })
  deleteOfferSpotUser!: (id: number) => Promise<SpotOfferUserType>;

  @Action('deleteOfferEventUser', { namespace: 'offer' })
  deleteOfferEventUser!: (id: number) => Promise<SpotOfferUserType>;

  @Action('changeOfferSpotUserStatus', { namespace: 'offer' })
  changeOfferSpotUserStatus!: (data: IOfferCard<OfferChangeSpotOfferStatusRequest>) => Promise<SpotOfferUserType>;

  @Action('changeOfferEventUserStatus', { namespace: 'offer' })
  changeOfferEventUserStatus!: (data: IOfferCard<OfferChangeEventOfferStatusRequest>) => Promise<EventOfferUserType>;

  @Getter('getLanguages', { namespace: 'info' })
  languages!: LanguageType[];

  @Getter('getCurrentLang', { namespace: 'lang' })
  currentLang!: string;

  @Watch('currentLang')
  resetGridHeader(newValue: string, oldValue: string) {
    if (this.agGrid && newValue !== oldValue) {
      this.agGrid.getGridApi()!.refreshHeader();
    }
  }

  created() {
    if (this.$route.params.id && this.$route.query.type) {
      this.entityId = this.$route.params.id;
      this.type = this.$route.query.type as string;
      this.getEntityRequest();
    } else {
      this.$router.back();
    }
  }

  beforeMount() {
    this.agGrid = new AgGridHelper(this.initGridOptionsOptions())
      .enableAutoSizeColumns(false)
      .enableInfinity(this.gridOffersChangeCallback)
      .build();
  }

  gridOffersChangeCallback(general: ICallbackGeneralParams, additional: ICallbackAdditionalParams) {
    additional.api.showLoadingOverlay();
    const action: any = this.isSpot ? this.getOfferSpotUsers : this.getOfferEventUsers;

    return action({
      id: this.entityId,
      params: {
        take: general.limit,
        skip: general.offset,
      }
    })
      .then(response => {
        additional.api.hideOverlay();
        return {
          result: response.items,
          count: response.paging.totalCount
        }
      });
  }

  initGridOptionsOptions(): GridOptions {
    return {
      columnDefs: this.offersColumnDef,
      enableSorting: false,
      suppressHorizontalScroll: true,
      rowHeight: 110,
    }
  }

  get offersColumnDef(): ColDef[] {
    return [
      {
        headerValueGetter: () => this.$t('offer.nameContacts'),
        field: 'name',
        width: 400,
        minWidth: 400,
        cellRenderer: 'userInfoCellRenderer',
        cellRendererParams: {
          name: (params: ICellRendererParams) => params.data && getUserName(params.data.user),
          url: (params: ICellRendererParams) => `/user/update/${params.data?.user?.id}`,
          phone: (params: ICellRendererParams) => params.data?.user?.phoneNumber,
          email: (params: ICellRendererParams) => params.data?.user?.email,
        }
      },
      {
        headerValueGetter: () => this.$t('offer.status'),
        field: 'status',
        cellRenderer: 'offerStatusCellRenderer',
        cellRendererParams: {
          status: (params: ICellRendererParams) => params.data?.status,
          onChange: this.onChangeStatus,
        }
      },
      {
        headerValueGetter: () => this.$t('offer.startAt'),
        field: 'startAt',
        valueGetter: (params: ValueGetterParams) => {
          return params.data && getDateFromISO(params.data.startAt, DATE_FORMAT);
        }
      },
      {
        headerValueGetter: () => this.$t('offer.endAt'),
        field: 'endAt',
        valueGetter: (params: ValueGetterParams) => {
          return params.data && (params.data.endAt ? getDateFromISO(params.data.endAt, DATE_FORMAT) : '');
        }
      },
      {
        headerName: '',
        field: 'actions',
        minWidth: 110,
        maxWidth: 110,
        width: 110,
        cellRenderer: 'actionsCellRenderer',
        cellRendererParams: {
          buttons: [
            {
              type: 'link',
              actionCallback: this.onDeleteOfferUser,
              btnContent: '<i class="fe-trash-2 text-danger"></i>',
            },
          ]
        }
      },
    ];
  }

  onChangeStatus(value: any, params: ICellRendererParams) {
    const action: any = this.isSpot ? this.changeOfferSpotUserStatus : this.changeOfferEventUserStatus;
    action({
      id: params.data.id,
      params: {
        status: value.toString()
      }
    });
  }

  onDeleteOfferUser(params: ICellRendererParams) {
    this.$modal.show('dialog', {
      title: '',
      text: this.$t('common.deleteQuestion'),
      buttons: [
        {
          title: 'Ok',
          handler: () => {
            this.$modal.hide('dialog');
            const action = this.isSpot ? this.deleteOfferSpotUser : this.deleteOfferEventUser;
            action(params.data.id)
              .then(() => {
                this.agGrid?.refreshInfinity();
              })
          }
        },
        {
          title: this.$t('common.cancel')
        }
      ]
    });
  }

  getEntityRequest() {
    const action: any = this.isSpot ? this.getOfferSpot : this.getOfferEvent;
    return action(this.entityId)
      .then(response => {
        this.entity = response;
        this.limit = response.limit;
        this.startAt = response.startAt;
        this.endAt = response.endAt;
        this.expiresAt = response.expiresAt;
        this.locales = [...response.locales];
      })
      .catch(() => this.$router.back());
  }

  get isSpot() {
    return this.type === TYPE_SPOT;
  }

  get languageOptions() {
    return [
      { value: '', text: '---' },
      ...(this.languages || []).map(item => ({ value: item.code, text: item.code.toUpperCase() }))
    ];
  }

  onApply() {
    if (!this.tryingToUpdate) {
      this.tryingToUpdate = true;
      const action: any = this.isSpot ? this.updateOfferSpot : this.updateOfferEvent;
      action({
        id: this.entityId,
        params: {
          limit: this.limit.toString(),
          expiresAt: this.expiresAt,
          startAt: this.startAt,
          endAt: this.endAt,
        }
      })
        .then(() => {
          this.tryingToUpdate = false;
          this.getEntityRequest();
        })
        .catch(() => {
          this.tryingToUpdate = false;
        });
    }
  }

  onUpdateLocale(event: LocaleUpdateEvent) {
    const updateLocale = this.isSpot ? this.updateOfferSpotLocale : this.updateOfferEventLocale;
    const createLocale = this.isSpot ? this.createOfferSpotLocale : this.createOfferEventLocale;
    const promise: Promise<any> = event.locale
      ? updateLocale({ id: event.locale.id, params: event.editedLocale as any })
      : createLocale({ id: this.entity!.id, params: event.editedLocale as any });

    promise.then(result => {
      this.locales = this.locales.filter(item => item.languageId !== event.language.id);
      this.locales.push(result);
    });
  }

  get entityName() {
    const entity = this.isSpot ? (this.entity as SpotOfferType).spot : (this.entity as EventOfferType).event;
    const language = getLanguageByCode(this.currentLang, this.languages);
    const locale = language ? (entity.locales as any).find(item => item.languageId === language.id) : null;
    return locale ? locale.name :
      (this.isSpot ? (this.entity as SpotOfferType).spotId : (this.entity as EventOfferType).eventId);
  }
}
